<template>
	<div style="height: 100%">
		<div style="padding: 20px; height: 800px">
			<!-- 搜素框 -->
			<el-form
				:inline="true"
				:model="params"
				style="
					padding-top: 20px;
					padding-left: 20px;
					margin-bottom: 20px;
					background-color: #fff;
					border-radius: 8px;
				"
				size="small"
			>
				<el-form-item label="充电桩类型">
					<el-input v-model="params.categoryName" placeholder="请输入充电桩类型"></el-input>
				</el-form-item>
				<el-form-item label="所属师傅">
					<el-select filterable v-model="params.userId" placeholder="请选择">
						<el-option
							v-for="item in userList"
							:key="item.id"
							:label="item.userName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button @click="checkData" type="primary" size="mini">查询</el-button>
					<el-button type="info" @click="reset" size="mini">重置</el-button>
				</el-form-item>
			</el-form>
			<div style="height: 82%; background-color: #fff; border-radius: 8px; padding-top: 8px">
				<el-button
					type="primary"
					size="small"
					style="float: right; margin-top: 20px; margin-right: 20px; margin-bottom: 20px"
					@click="add()"
					v-show="authorization('/home-admin/device/device-class/add')"
					>新增</el-button
				>
				<el-table
					:data="tableData"
					stripe
					style="padding: 0 20px"
					height="80%"
					size="small"
					:header-cell-style="{
						'background-color': '#84aace4a',
						color: '#343333',
						'text-align': 'center',
					}"
					:cell-style="{ 'text-align': 'center' }"
				>
					<el-table-column prop="categoryName" label="设备类型名"></el-table-column>
					<el-table-column prop="outCount" label="已使用数量"></el-table-column>
					<el-table-column prop="inputCount" label="未使用数量"></el-table-column>
					<el-table-column prop="lockCount" label="转借中数量"></el-table-column>
					<el-table-column prop="badCount" label="坏桩数量"></el-table-column>
					<el-table-column
						prop="limitStock"
						label="是否限制库存"
						:formatter="limitStockFormat"
					></el-table-column>
					<el-table-column prop="orderIndex" label="排序" width="60"></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-tooltip
								effect="dark"
								content="编辑"
								placement="top-end"
								v-show="authorization('/home-admin/device/device-class/edit')"
							>
								<el-button
									type="primary"
									icon="el-icon-edit"
									circle
									size="mini"
									@click="edit(scope.row)"
									style="margin-right: 5px"
								></el-button>
							</el-tooltip>
							<el-tooltip
								effect="dark"
								content="删除"
								placement="top-end"
								v-show="authorization('/home-admin/device/device-class/del')"
							>
								<el-popconfirm title="确认删除吗？" @confirm="deleteDeviceClass(scope.row)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										circle
										size="mini"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div
					style="
						padding-top: 20px;
						height: 40px;
						background-color: #fff;
						width: 100%;
						border-radius: 8px;
						text-align: right;
					"
				>
					<el-pagination
						background
						:page-sizes="sizes"
						layout="total,sizes,prev,pager,next"
						:total="total"
						@current-change="handleCurrentChange"
						@size-change="sizeChange"
					></el-pagination>
				</div>
			</div>
		</div>

		<el-dialog
			title="新增"
			:visible.sync="dialogFormAdd"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="addClassParams"
				:rules="rules"
				ref="addClassParams"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="类型名" prop="categoryName">
					<el-input v-model="addClassParams.categoryName"></el-input>
				</el-form-item>
				<el-form-item label="是否限制库存" prop="limitStock" label-width="160px" size="small">
					<el-select
						v-model="addClassParams.limitStock"
						placeholder="请选择是否限制库存"
						style="width: 100%"
					>
						<el-option label="限制库存" :value="1"></el-option>
						<el-option label="不限制库存" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="orderIndex">
					<el-input-number v-model="addClassParams.orderIndex" :min="0"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="addSubMit('addClassParams')" size="mini">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog
			title="编辑"
			:visible.sync="dialogFormEdit"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="addClassParams"
				:rules="rules"
				ref="addClassParams"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="类型名" prop="categoryName">
					<el-input v-model="addClassParams.categoryName"></el-input>
				</el-form-item>
				<el-form-item label="是否限制库存" prop="limitStock" label-width="160px" size="small">
					<el-select
						v-model="addClassParams.limitStock"
						placeholder="请选择是否限制库存"
						style="width: 100%"
					>
						<el-option label="限制库存" :value="1"></el-option>
						<el-option label="不限制库存" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="orderIndex">
					<el-input-number v-model="addClassParams.orderIndex" :min="0"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="editSubMit('addClassParams')" size="mini"
					>确 定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { authorization } from '../../../authorization/authorization';
	export default {
		data() {
			return {
				userList: [],
				dialogFormAdd: false,
				dialogFormEdit: false,
				tableData: [],
				params: {
					page: 1,
					size: 10,
					userId: null,
				},
				addClassParams: {
					categoryName: '',
					limitStock: '',
				},
				rules: {
					categoryName: [{ required: true, message: '请输入类型名', trigger: 'blur' }],
					limitStock: [{ required: true, message: '请选择是否限制库存', trigger: 'blur' }],
				},
				total: 0,
				sizes: [10, 15],
			};
		},
		created() {
			this.queryClassList();
			this.queryUserList();
		},
		methods: {
			authorization,
			limitStockFormat: function (row) {
				if (row.limitStock != undefined && row.limitStock != null && row.limitStock == 2) {
					return '不限制库存';
				} else {
					return '限制库存';
				}
			},
			checkData() {
				this.queryClassList();
			},
			reset() {
				this.params = { page: 1, size: 10, userId: null };
				this.queryOrderList();
			},
			queryUserList() {
				//所属师傅下拉（包含暂不接单的师傅）
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/select-master-drown',
				}).then((res) => {
					this.userList = [];
					let obj = {};
					obj.id = 0;
					obj.userName = '全部';
					this.userList.push(obj);
					for (let i = 0; i < res.data.data.list.length; i++) {
						let userInfo = res.data.data.list[i].userName;
						userInfo = userInfo.substring(0, userInfo.indexOf('('));
						res.data.data.list[i].userName = userInfo;
						this.userList.push(res.data.data.list[i]);
					}
				});
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryClassList();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryClassList();
			},
			queryClassList() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
					params: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
					this.total = res.data.data.total;
				});
			},
			add() {
				//新增弹出层
				this.dialogFormAdd = true;
				this.addClassParams = {
					categoryName: '',
					limitStock: '',
				};
			},
			addSubMit(formName) {
				//新增提交
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/device-category/add-category',
							data: this.addClassParams,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormAdd = false;
								this.$message({ message: '添加成功', type: 'success' });
								this.queryClassList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			edit(row) {
				//修改弹出层
				this.dialogFormEdit = true;
				this.addClassParams = row;
			},
			editSubMit(formName) {
				//修改提交
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/device-category/update-category',
							data: this.addClassParams,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormEdit = false;
								this.$message({ message: '修改成功', type: 'success' });
								this.queryClassList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			deleteDeviceClass(row) {
				//删除设备
				this.axios({
					method: 'delete',
					url: '/v1/web/device-category/delete-category',
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.queryClassList();
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
		},
	};
</script>

<style scoped></style>
